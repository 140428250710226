import React from "react";
import Footer from "../Footer/Footer";
import img1 from "../Image/Header2.png";
import { FaDiscord } from "react-icons/fa";
import { BsTwitter } from "react-icons/bs";
import { BsMedium } from "react-icons/bs";
import "./Main.css";
import MainSlider from "./MainSlider";
const Main = () => {
  return (
    <div className="main">
      <a name="about"></a>
      <div className="container">
        <div className="col-10 mx-auto px-3">
          {/* <div className="icon-part d-flex justify-content-center mt-5">
            <div className="twitter mx-3">
              <a href="https://twitter.com/FoxNationDAO" target="_blank">
                <BsTwitter
                  style={{
                    fontSize: "40px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
            <div className="discord mx-3">
              <a href="https://discord.com/invite/foxnationdao" target="_blank">
                <FaDiscord
                  style={{
                    fontSize: "40px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
            <div className="medium mx-3">
              <a href="https://medium.com/@FoxNationDAO" target="_blank">
                <BsMedium
                  style={{
                    fontSize: "40px",
                    color: "black",
                    cursor: "pointer",
                  }}
                />
              </a>
            </div>
          </div> */}

          <div className="main-img-center text-center">
            {/* <a href="https://medium.com/@FoxNationDAO" target="_blank">
            <img
              src={img1}
              alt=""
              className="img-center mb-5 text-center"
              data-aos="fade-up"
            />
          </a> */}

            {/* <div className="main-text text-center mb-3">
              <div className="main-text-title mb-4" data-aos="fade-up"> */}
            <div className="title text-center py-5">
              <div className="title-main mb-5">DO THE UNIVERSE COLLECTION</div>
              <div className="description">
                {/* The DoTheUniverse are coming to give the final blow to the
                Goblins. <br />
                They bring a Magic Potion and a rich 10 ETH Raffle for their
                adepts. <br /> <br /> */}
                7,777 idiots teleported on the blockchain, messing up
                everything. <br />
                They will destroy blocks, fuck up with hashes, network
                difficulty and everything they can put their hands on. <br />
                The Ethereum blockchain won't be the same again. <br />
                This collection came to pay homage to the launch of the new
                Beavis and Butt-head movie "Beavis and Butt-head do the
                Universe".
                <br /> <br />
                Smart contract for real junkies{" "}
                <a
                  href="https://etherscan.io/address/0x063d4d56514a343002058e3998e9536a24b3cb01#code"
                  target="_blank"
                >
                  here
                </a>
                . <br />
                {/* Magic Potion to claim for holders, turn your witch in a princess{" "}
                <br /> <br />
                10 ETH Raffle: 1 lucky holder will get 10 ETH */}
                {/* Check our{" "}
                <a
                  className="main-link"
                  href="https://medium.com/@DoTheUniverse"
                  target="_blank"
                >
                  Medium{" "}
                </a>
                for detailed info. */}
              </div>
            </div>
            <a href="https://twitter.com/DoTheUniverse_/" target="_blank">
              <img
                src={img1}
                alt=""
                className="img-center text-center img-fluid"
                // data-aos="fade-up"
              />
              {/* <MainSlider /> */}
            </a>

            {/* main button part  */}
            {/* <div className="main-bottom-buttom">
						<div className="left-btn1">
							<button className="left-btn mb-3" data-aos="zoom-in">
								Buy On Opensea
							</button>
						</div>
					</div> */}
            {/* <span className="bottom-text mt-5 mb-5" data-aos="zoom-in">
              Be careful of fake Fox Nation. Use the <br /> button to get the
              right collection.
            </span> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Main;
