import React from "react";
import Content from "./Roadmap";
import img2 from "../Image/Header1.png";

import "./Roadmap.css";
const Roadmap = () => {
  return (
    <>
      <div className="roadmap">
        <div className="container">
          <a name="roadmap"></a>
          <div className="col-10 mx-auto p-3">
            <div className="padding">
              <div className="title-info">ROADMAP</div>
            </div>
            <div className="description mb-3">
              Sold Out:
              <br />
              {/* Get the Capsule and turn the idiots in....something else.
              Something even worst than they actually are. <br /> */}
              We are going to open the Discord, so the idiots won't go around.
              Don't list below 0.1 to be admitted. <br /> <br />
              100 ETH Vol:
              <br />
              2.5 ETH Raffle among holders (but only if you list at least at
              0.1, paperhand). <br />
              <br />
              250 ETH Vol:
              <br /> Charity Donation: 7.5 ETH go to Mental Health found to help
              some of you degen rekt. <br />
              <br />
              500 ETH Vol:
              <br /> Sofas Collection: let the idiots sit and watch TV. <br />
              You will be able to mint 1 Sofa for each B&B you hold. Only for no
              paperhands Holders.
              <br />
              <br />
              1000 ETH Vol:
              <br /> 20 ETH Raffle among holders. <br />
              <br />
              5000 ETH Vol:
              <br /> 50 ETH (!!!) Raffle among holders + 25 ETH Charity
              donation.
              <br />
              <a
                href="https://opensea.io/collection/dotheuniverse"
                target="_blank"
              >
                <img
                  src={img2}
                  alt=""
                  className="img-center text-center img-fluid mt-5"
                  // data-aos="fade-up"
                />
                {/* <MainSlider /> */}
              </a>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="groups">
        <div className="subtitle text-center padding" data-aos="fade-up">
          Working Groups Phases
        </div>

        <div class="container mb-5 box">
          <div class="timeline">
            <ul>
              <li>
                <div
                  className="timeline-content"
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-sine"
                >
                  <h1>Phase 1</h1>
                  <p>
                    50% Community Member Delegates
                    <br />
                    50% Team Delegates
                    <br />
                    <br />
                    Decentralization will happen through a process. In the first
                    phase, the working group's delegates will be constituted in
                    a balanced way of 50% team members and the other 50% by the
                    most active and skilled holders. Delegates and Discussion
                    Members will be able to apply based on their expertise and
                    will be chosen through a DAO votation.
                  </p>
                </div>
              </li>
              <li>
                <div
                  class="timeline-content"
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-sine"
                >
                  <h1>Phase 2</h1>
                  <p>
                    75% Community Member Delegates
                    <br />
                    25% Team Delegates
                    <br />
                    <br />
                    In the second phase, the community will start to have a
                    majority of working group delegates, who will always be
                    elected through DAO votes. Anyway, 25% of the group's
                    delegates will be still reserved for members of the founding
                    team as protection in the creation of proposals for treasury
                    use.
                  </p>
                </div>
              </li>
              <li>
                <div
                  class="timeline-content"
                  data-aos="zoom-in"
                  data-aos-easing="ease-in-sine"
                >
                  <h1>Phase 3</h1>
                  <p>
                    100% Community Member Delegates <br />
                    <br />
                    This is the last step of the project. All working group
                    members' delegates will be chosen by the community which
                    will have total autonomy in treasury management. We believe
                    in a totally decentralized and self-managed ecosystem where
                    each members can aspire to their own economic independence.
                  </p>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default Roadmap;
