import React, { useEffect, useState } from "react";
import {
  purchaseTokensFirstFree,
  purchaseTokensPaid,
  purchaseTokensPlusPurchaseTokensFree,
} from "../../eth-setup/apis";
import img2 from "../Image/Mint.gif";

import "./Mint.css";
const Mint = () => {
  const maxVal = 10;
  const [qty, setQty] = useState(1);

  // useEffect(() => {
  //   if (value > 3) setValue(3);
  //   else if (value < 1) setValue(1);
  // }, [value]);

  return (
    <div className="mint-main">
      <div className="container" id="mint">
        <div className="right col-10 mx-auto p-3">
          <div className="title-info padding">MINTING</div>
          {/* <div className="right-title mb-3 text-center">Mint Here</div> */}
          <div className="description text-center">
            {/* 8888 Apes - 2000 Free Mint (max 2 x wallet) <br />
            0.0069 ETH the others (max 10 x wallet). <br /> */}
            {/* Instant Reveal. <br /> */}
            7,777 Idiots on the Ethereum Blockchain. <br />
            First 5,000 Free Mint (max 5x wallet). <br />
            then 0.01 each (max 10x wallet). <br />
            {/* 777 reserved for the team. <br /> */}
            100% IP ownership in the hands of the holders. No bullshit.
            {/* 2000 reserved for DoTheUniverse <br />
            2000 free to mint for everyone <br /> (1 free + gas mint per wallet){" "}
            <br /> <br />
            remaining amount 0.01 ETH each <br />
            (max 5 per wallet) <br /> */}
          </div>
          <div className="mint text-center mt-4">
            {/* <div className="mint-title mb-2">{total}/5000</div> */}
            {/* <div className="mint-input d-flex justify-content-center align-items-center">
              <div
                className="btn-minus mx-3"
                onClick={() => setValue(value - 1)}
              >
                <i class="fas fa-minus"></i>
              </div>
              <div className="input mx-3">{value}</div>

              <div
                className="btn-plus mx-3"
                onClick={() => setValue(value + 1)}
              >
                <i class="fas fa-plus"></i>
              </div>
            </div> */}
            <div>
              <div className="mint-btn mb-3 mt-4">
                {/* <button
                                type="button"
                                class="btn btn-primary mint-btn-submit"
                                onClick={() =>
                                    purchaseTokensPlusPurchaseTokensFree(() => {}, value)
                                } 
                             > */}

                <input
                  type="number"
                  className="number btn-primary mint-btn-submit text-center"
                  onChange={(e) => {
                    if (Number(e.target.value) > maxVal) setQty(maxVal);
                    else if (e.target.value === "") setQty(e.target.value);
                    else if (Number(e.target.value) < 1) setQty(1);
                    else setQty(e.target.value);
                  }}
                  onClick={(e) => {
                    if (Number(e.target.value) > maxVal) setQty(maxVal);
                    else if (e.target.value === "") setQty(e.target.value);
                    else if (Number(e.target.value) < 1) setQty(1);
                    else setQty(e.target.value);
                  }}
                  value={qty}
                  id=""
                />
                {/* INPUT HERE
                            </button> */}
              </div>
              <div className="mint-btn mb-4 mt-4">
                <button
                  type="submit"
                  class="btn btn-primary mint-btn-submit"
                  onClick={() =>
                    purchaseTokensPlusPurchaseTokensFree(() => {}, qty)
                    // purchaseTokensPaid(() => {}, qty)
                    // purchaseTokensFirstFree(() => {}, qty)
                  }
                >
                  MINT HERE
                </button>
              </div>
              <a
                href="https://opensea.io/collection/dotheuniverse"
                target="_blank"
              >
                <img
                  src={img2}
                  alt=""
                  className="img-mint text-center img-fluid"
                  // data-aos="fade-up"
                />
                {/* <MainSlider /> */}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Mint;
